import logo from "../assets/logo.png";
export default function LogoIcon(props: { onClick: any }) {
  return (
    <button
      className="flex flex-grow items-center"
      onClick={() => {
        props.onClick();
      }}
    >
      <img className="h-8" src={logo} alt="logo" />
    </button>
  );
}
