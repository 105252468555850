import demo from "../assets/demo.jpg";

import Button from "./Button";
import { useNavigate } from "react-router-dom";

export default function ReadyToStartSection(props: { background: string }) {
  const navigate = useNavigate();
  return (
    <div
      className={
        "border-t border-b px-4 flex bg-gray-50 py-10 flex-col w-screen justify-between bg-left bg-no-repeat bg-contain " +
        "xs:px-8 " +
        "sm:px-12 " +
        "md:px-16 " +
        "lg:px-32 lg:flex-row " +
        "xl:px-42 xl:flex-row " +
        "2xl:px-48 2xl:flex-row " +
        props.background
      }
    >
      <div
        className={
          "my-32 w-full " +
          "xs:w-full " +
          "sm:w-full " +
          "md:w-full " +
          "lg:w-5/12" +
          "xl:w-5/12 " +
          "2xl:w-5/12  "
        }
      >
        <div className="text-4xl font-sofia font-bold mb-8">
          Ready to get started ?
        </div>
        <div className="text-xl font-rubik font-light mb-4">
          Learn how our no code platform works to boost productivity and
          automate the process.
        </div>
        <Button
          text="Get Demo"
          variant="solid"
          size="md"
          extraStyles="my-3"
          onClick={() => {
            navigate("/get-demo");
          }}
        />
      </div>
      <img
        src={demo}
        alt="why"
        className={"w-6/12 h-fit hidden " + "lg:flex"}
      />
    </div>
  );
}
