export default function Button(props: {
  size: "sm" | "md" | "lg";
  variant: "simple" | "solid" | "icon";
  text: string;
  extraStyles?: string;
  onClick: any;
}) {
  return (
    <button
      onClick={() => {
        props.onClick();
      }}
      className={
        "w-fit " +
        getDivStyle(props.size) +
        getVariantDivStyle(props.variant) +
        props.extraStyles
      }
    >
      <div
        className={
          getTextStyle(props.size) + getVariantTextStyle(props.variant)
        }
      >
        {props.text}
      </div>
    </button>
  );
}

function getDivStyle(size: "sm" | "md" | "lg") {
  switch (size) {
    case "sm":
      return "p-1.5 ";
    case "md":
      return "px-3 py-2 ";
    case "lg":
      return "p-4 ";
  }
}

function getVariantDivStyle(variant: "simple" | "solid" | "icon") {
  switch (variant) {
    case "simple":
      return "bg-transparent ";
    case "solid":
      return "bg-gray-900 rounded-sm ";
    case "icon":
      return "bg-transparent ";
  }
}

function getVariantTextStyle(variant: "simple" | "solid" | "icon") {
  switch (variant) {
    case "simple":
      return "text-gray-900 font-sofia";
    case "solid":
      return "text-white font-medium font-sofia";
    case "icon":
      return "text-gray-900 font-sofia";
  }
}

function getTextStyle(size: "sm" | "md" | "lg") {
  switch (size) {
    case "sm":
      return "text-sm ";
    case "md":
      return "text-md ";
    case "lg":
      return "text-lg ";
  }
}
