import LogoButton from "../../components/LogoButton";
import { useNavigate } from "react-router-dom";
export default function GetDemo() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-screen h-screen items-center ">
      <div className="flex flex-row w-screen p-8 mb-12">
        <LogoButton
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="font-rubik text-2xl font-light text-gray-700 mb-10">
        Try <span className="font-bold">KonfigAI</span> free for 14days
      </div>

      <div
        className={
          "w-10/12 shadow-[0_10px_60px_rgba(0,0,0,0.1)] bg-white rounded p-8 border border-gray-100 " +
          "xs:w-10/12 " +
          "sm:w-9/12 " +
          "md:w-8/12 " +
          "lg:w-7/12 " +
          "xl:w-5/12 " +
          "2xl:w-4/12"
        }
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
              FIRST NAME
            </label>
            <input
              id="firstname"
              placeholder="John"
              className="border border-gray-200 rounded px-3 py-2 text-sm"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
              LAST NAME
            </label>
            <input
              id="firstname"
              placeholder="Doe"
              className="border border-gray-200 rounded px-3 py-2 text-sm"
            />
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
            WORK EMAIL
          </label>
          <input
            id="firstname"
            placeholder="john@doe.com"
            className="border border-gray-200 rounded px-3 py-2 text-sm"
          />
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
            ORGANIZATION NAME
          </label>
          <input
            id="firstname"
            placeholder="ABC organization"
            className="border border-gray-200 rounded px-3 py-2 text-sm"
          />
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
            ORGANIZATION SIZE
          </label>
          <input
            id="firstname"
            placeholder="1"
            className="border border-gray-200 rounded px-3 py-2 text-sm"
          />
        </div>{" "}
        <div className="flex flex-col mt-4">
          <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
            INDUSTRY
          </label>
          <input
            id="firstname"
            placeholder="FMCG"
            className="border border-gray-200 rounded px-3 py-2 text-sm"
          />
        </div>
        <div className="flex flex-col mt-4">
          <label className="font-sofia font-regular text-md mb-1.5 text-gray-600">
            PHONE NUMBER
          </label>
          <input
            id="firstname"
            placeholder="1231356"
            className="border border-gray-200 rounded px-3 py-2 text-sm"
          />
        </div>
        <button className="bg-brand-6 text-lg font-sofia font-medium px-3 py-2 text-white rounded w-full mt-6">
          Submit
        </button>
      </div>
    </div>
  );
}
