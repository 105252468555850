import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import solution from "../../assets/solution.jpg";
import financialServices from "../../assets/financialServices.jpg";
import insurance from "../../assets/insurance.jpg";
import publicSector from "../../assets/publicsector.jpg";
import retail from "../../assets/retail.jpg";
import healthcare from "../../assets/healthcare.jpg";

import SolutionSection from "../../components/SolutionSection";

export default function MainSection() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <div
        className={
          "flex flex-col px-4 justify-between py-32 " +
          "xs:px-8 " +
          "sm:px-12 " +
          "md:px-16 " +
          "lg:px-32 lg:flex-row " +
          "xl:px-42 xl:flex-row " +
          "2xl:px-48 2xl:flex-row "
        }
      >
        <div
          className={"flex flex-col w-full justify-center pr-8 " + "lg:w-5/12"}
        >
          <div className="font-rubik text-xs mb-4 tracking-wider text-brand-6 font-medium">
            SOLUTIONS
          </div>
          <div className="text-6xl font-sofia font-bold mb-4">
            Simplify your work processes
          </div>
          <div className="text-xl font-rubik font-light mb-4">
            Increase productivity in every area of your business with an
            adaptable, all-in-one data automation platform.
          </div>
          <Button
            text="Get Demo"
            variant="solid"
            size="md"
            extraStyles="my-3"
            onClick={() => {
              navigate("/get-demo");
            }}
          />
        </div>
        <img
          src={solution}
          className={"w-full h-fit " + "lg:flex lg:w-6/12"}
          alt="something"
        />
      </div>
      <SolutionSection
        title1="Financial services"
        title2="Revolutionize financial services"
        paragraph={
          <p>
            In today's fast-paced financial services industry, businesses need
            to keep up with the demands of customers, regulations, and
            compliance. With KonfigAI's no-code platform, you can build
            customized software solutions quickly and easily, without the need
            for complex coding. From KYC to AML to underwriting, streamline your
            operations and improve efficiency while ensuring compliance with
            regulations. Simplify your workflow and stay ahead of the
            competition with KonfigAI's no-code solution for the financial
            services industry.
          </p>
        }
        highlights={["Client onboarding", "Withdrawals", "Wealth management"]}
        imgSrc={financialServices}
        extraStyles="bg-gray-50"
      />
      <SolutionSection
        title1="Insurance
        "
        title2="Scale operations at market speed"
        paragraph={
          <p>
            The Insurance industry is constantly evolving, and insurers must
            adapt to stay competitive. Traditional software development can be
            time-consuming and expensive, and inflexible. That's where our
            no-code platform comes in. our no-code platform provides insurers
            with a powerful tool for building custom applications and workflows
            that address their unique needs and challenges. With our platform,
            insurers can leverage alternative credit data sources to create more
            robust underwriting risk profiles. Insurers can create more
            efficient, agile, and customer-centric operations, ultimately
            improving their bottom line and customer satisfaction.
          </p>
        }
        highlights={["Under writing", "Claims processing", "Re-insurance"]}
        extraStyles="bg-white"
        imgSrc={insurance}
      />
      <SolutionSection
        title1="Healthcare"
        title2="Empower healthcare innovation"
        paragraph={
          <p>
            The healthcare industry is constantly evolving, and innovation is
            essential to stay ahead. With KonfigAI's no-code platform,
            healthcare professionals can create customized software solutions
            without extensive coding knowledge. From telemedicine to patient
            management systems, our intuitive drag-and-drop tools make it easy
            to streamline your workflow and improve patient care. With our
            no-code platform, you can innovate and transform healthcare, making
            it more efficient and accessible for everyone.
          </p>
        }
        highlights={[
          "Patient registration",
          "Member onboarding",
          "Regulatory & Compliance",
        ]}
        extraStyles="bg-gray-50"
        imgSrc={healthcare}
      />
      <SolutionSection
        title1="Public sector"
        title2="Modernize and optimize every government process"
        paragraph={
          <p>
            As a marketplace, you need to focus on growing your business and
            delivering an exceptional customer experience, not building internal
            tooling. KonfigAI's dynamic no-code platform can help you quickly
            build the tools you need to scale your operations. From resolving
            customer support tickets to onboarding new partners and managing
            your supply-side, our drag-and-drop tools make it easy to create
            custom apps that your sales, ops, and support teams can use. With
            our platform, you can monitor markets with real-time charts and maps
            and connect to any API, database, or tool you need. Say goodbye to
            error-prone scripts and hello to efficient, scalable operations with
            KonfigAI's no-code platform for marketplaces.
          </p>
        }
        highlights={[
          "Streamlined grants management",
          "Improved investigative case management",
          "Modernized licensing process",
        ]}
        extraStyles="bg-white"
        imgSrc={publicSector}
      />
      <SolutionSection
        title1="Retail & E-commerce"
        title2="Streamline and scale your retail operations"
        paragraph={
          <p>
            As your retail or e-commerce business grows, so does the complexity
            of your operations. But with KonfigAI's no-code platform, you can
            streamline your back-office processes and keep up with increasing
            demands. Empower non-technical analysts to manage inventory,
            procurement, and supply chain operations, freeing up your
            engineering team to focus on delivering a better customer
            experience.
          </p>
        }
        highlights={[
          "Automated distribution process",
          "Supplier onboarding",
          "Procurement process",
        ]}
        extraStyles="bg-gray-50"
        imgSrc={retail}
      />
    </div>
  );
}
