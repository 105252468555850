import { Bars3Icon } from "@heroicons/react/24/solid";
import Button from "./Button";
import LogoIcon from "./LogoButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function TopBar() {
  const navigate = useNavigate();
  const [isDropVisible, setIsDropVisible] = useState(false);
  return (
    <>
      <div
        className={
          "hidden flex-row align-middle items-center py-7 fixed w-screen bg-white z-50 " +
          "xs:hidden xs:px-8 " +
          "sm:hidden sm:px-12 " +
          "md:flex md:px-16 " +
          "lg:flex lg:px-32 " +
          "xl:flex xl:px-42 " +
          "2xl:flex 2xl:px-48"
        }
      >
        <LogoIcon
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="flex">
          <Button
            variant="simple"
            extraStyles="mx-2"
            onClick={() => {
              navigate("/solutions");
            }}
            text="Solutions"
            size="md"
          />
          <Button
            variant="simple"
            extraStyles="mx-2"
            onClick={() => {
              navigate("/integrations");
            }}
            text="Integrations"
            size="md"
          />
          <Button
            variant="solid"
            extraStyles="mx-2"
            onClick={() => {
              navigate("/get-demo");
            }}
            text="Get Demo"
            size="md"
          />
        </div>
      </div>

      <div
        className={
          "flex px-4 flex-col fixed w-screen bg-white z-50 " +
          "xs:flex xs:px-8 " +
          "sm:flex sm:px-12 " +
          "md:hidden " +
          "xl:hidden " +
          "2xl:hidden "
        }
      >
        <div className={"flex flex-row align-middle items-center py-7 "}>
          <LogoIcon
            onClick={() => {
              navigate("/");
            }}
          />
          <Button
            variant="solid"
            extraStyles="mx-2"
            onClick={() => {
              navigate("/get-demo");
            }}
            text="Get Demo"
            size="md"
          />
          <Bars3Icon
            className="w-8 h-8 text-gray-900 cursor-pointer ml-2"
            onClick={() => {
              setIsDropVisible(!isDropVisible);
            }}
          />
        </div>
        {isDropVisible && (
          <div className="flex flex-col items-start mb-2">
            <button
              className="p-2 font-medium w-full text-left"
              onClick={() => {
                navigate("/solutions");
              }}
            >
              Solutions
            </button>
            <button
              className="p-2 font-medium w-full text-left"
              onClick={() => {
                navigate("/integrations");
              }}
            >
              Integrations
            </button>
          </div>
        )}
      </div>
    </>
  );
}
