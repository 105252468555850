import FeatureSection from "./FeatureSection";
import MainSection from "./MainSection";
import ProductSection from "./ProductSection";
import ReadyToStartSection from "../../components/ReadyToStartSection";
import TopBar from "../../components/TopBar";

export default function Home() {
  return (
    <div className="flex flex-col  ">
      <TopBar />
      <div className="py-1" />
      <MainSection />
      <FeatureSection />
      <ProductSection />
      <ReadyToStartSection background="bg-gray-100" />
    </div>
  );
}
