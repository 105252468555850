import mainSvg from "../../assets/main.svg";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

export default function MainSection() {
  const navigate = useNavigate();
  return (
    <div
      className={
        "justify-between px-4 mb-4 flex flex-col align-middle items-center " +
        "xs:px-8 " +
        "sm:px-12 " +
        "md:px-16 " +
        "lg:px-32 lg:flex-row " +
        "xl:px-42 xl:flex-row " +
        "2xl:px-48 2xl:flex-row "
      }
    >
      <div
        className={
          "flex flex-col mt-6 py-16 w-full relative z-10 " +
          "xs:py-16 " +
          "md:py-32 " +
          "lg:w-5/12 lg:py-64 " +
          "xl:w-5/12 xl:py-64 " +
          "2xl:w-5/12 2xl:py-64"
        }
      >
        <div
          className={
            "pt-8 font-sofia text-6xl font-bold mb-8 text-gray-900 " + "lg:pt-4"
          }
        >
          No-Code with endless possibilities
        </div>
        <div className="font-rubik text-xl font-thin mb-4 text-gray-700">
          Customized, intuitive, no code platform to empower business to create
          personalized software solutions from ground up without complicated
          code or expensive fees.
        </div>
        <Button
          text="Get Demo"
          variant="solid"
          size="md"
          extraStyles="my-3"
          onClick={() => {
            navigate("/get-demo");
          }}
        />
      </div>
      <div className="flex h-full flex-grow justify-center">
        <img className="h-[36rem] relative z-10" src={mainSvg} alt="yolo" />
      </div>
      <div
        className={
          "hidden bg-[url('assets/main_background.svg')] top-16 absolute z-0 w-[44rem] h-[44rem] bg-no-repeat bg-contain bg-righ " +
          "xs:hidden xs:right-4 " +
          "sm:hidden sm:right-6 " +
          "md:hidden md:right-12 " +
          "lg:flex lg:right-16 " +
          "xl:flex xl:right-24 " +
          "2xl:flex 2xl:right-32"
        }
      />
    </div>
  );
}
