import diagFormBuilder from "../../assets/diagFormBuilder.svg";
import diagTransformations from "../../assets/diagTransformations.svg";
import diagWorkflowDesigner from "../../assets/diagWorkflowDesigner.svg";
import diagWorkflowUI from "../../assets/diagWorkflowUI.svg";

export default function ProductSection() {
  return (
    <div
      className={
        "flex px-4 flex-col " +
        "xs:px-8 " +
        "sm:px-12 " +
        "md:px-16 " +
        "lg:px-32  " +
        "xl:px-42 " +
        "2xl:px-48  "
      }
    >
      <div className="flex flex-col mb-32">
        <div className="font-bold text-4xl font-sofia text-center mb-4 text-gray-900">
          Stop working & Start automating
        </div>
        <div className="font-light text-lg font-rubik text-center px-16 text-gray-700">
          Elevate your work with our no-code platform. Seamlessly integrate
          services using our modular architecture, delivering products that
          align with customer needs. Customize applications and keep up with
          evolving business needs with our flexible tools.
        </div>
      </div>
      {/* --------- Form builder section here ------- */}
      <div
        className={
          "flex flex-col mb-32 " +
          "lg:flex-row " +
          "xl:flex-row " +
          "2xl:flex-row"
        }
      >
        <div className={"flex flex-col justify-center " + "md:pr-32 "}>
          <div className="font-sofia text-3xl font-bold mb-4 text-gray-900">
            Modernize the frontend experience
          </div>
          <div className="font-rubik text-lg font-light mb-4 text-gray-700">
            Our Form Builder simplifies frontend application design with easy
            drag-and-drop widgets like dropdowns, checkboxes, and text fields.
            Create intuitive interfaces that offer a sleek user experience and
            customize them effortlessly for a seamless and enjoyable process.
          </div>
        </div>
        <img
          src={diagFormBuilder}
          alt="i dont know"
          className={
            "w-[100%] " + "lg:w-[45%] " + "xl:w-[45%] " + "2xl:w-[45%]"
          }
        />
      </div>
      {/* --------- Workflow design section here ------- */}
      <div
        className={
          "flex flex-col mb-32 " +
          "lg:flex-row-reverse " +
          "xl:flex-row-reverse " +
          "2xl:flex-row-reverse"
        }
      >
        <div className={"flex flex-col justify-center " + "lg:pl-32 "}>
          <div className="font-sofia text-3xl font-bold mb-4 text-gray-900">
            Dynamic workflow orchestration
          </div>
          <div className="font-rubik text-lg font-light mb-4 text-gray-700">
            Invest in KonfigAI for a user-friendly workflow orchestration
            platform. Create digital workflows and business processes without
            programming skills. Automate processes with alerts, notifications,
            and algorithms. Experience seamless efficiency with KonfigAI.
          </div>
        </div>
        <img
          src={diagWorkflowDesigner}
          alt="i dont know"
          className={
            "w-[100%] " + "lg:w-[45%] " + "xl:w-[45%] " + "2xl:w-[45%]"
          }
        />
      </div>
      {/* --------- Transformations section here ------- */}
      <div
        className={
          "flex flex-col mb-32 " +
          "lg:flex-row " +
          "xl:flex-row " +
          "2xl:flex-row"
        }
      >
        <div className={"flex flex-col justify-center " + "lg:pr-32 "}>
          <div className="font-sofia text-3xl font-bold mb-4 text-gray-900">
            Build and integrate APIs
          </div>
          <div className="font-rubik text-lg font-light mb-4 text-gray-700">
            Opt for our platform's drag-and-drop interface to connect
            components, execute APIs, and create unified APIs without manual
            coding. Our visual data mapping tools simplify the process,
            streamlining business processes, automating workflows, and boosting
            productivity.
          </div>
        </div>
        <img
          src={diagTransformations}
          alt="i dont know"
          className={
            "w-[100%] " + "lg:w-[45%] " + "xl:w-[45%] " + "2xl:w-[45%]"
          }
        />
      </div>
      {/* --------- Workflow UI section here ------- */}
      <div
        className={
          "flex flex-col mb-32 " +
          "lg:flex-row-reverse " +
          "xl:flex-row-reverse " +
          "2xl:flex-row-reverse"
        }
      >
        <div className={"flex flex-col justify-center " + "lg:pl-32 "}>
          <div className="font-sofia text-3xl font-bold mb-4 text-gray-900">
            Operations UI
          </div>
          <div className="font-rubik text-lg font-light mb-4 text-gray-700">
            Experience streamlined workflow, task, and data management with our
            Operations UI. Seamlessly monitor progress, receive notifications,
            and access real-time data insights. Enhance transparency and
            visibility, improve efficiency, and make informed decisions with our
            platform.
          </div>
        </div>
        <img
          src={diagWorkflowUI}
          alt="i dont know"
          className={
            "w-[100%] " + "lg:w-[45%] " + "xl:w-[45%] " + "2xl:w-[45%]"
          }
        />
      </div>
    </div>
  );
}
