import { CheckCircleIcon } from "@heroicons/react/24/solid";

export default function SolutionSection(props: {
  title1: string;
  title2: string;
  paragraph: any;
  highlights: string[];
  imgSrc: any;
  extraStyles: string;
}) {
  return (
    <div
      className={
        "border-t border-gray-200 px-4 flex flex-col py-24 " +
        "xs:px-8 " +
        "sm:px-12 " +
        "md:px-16 " +
        "lg:px-32 " +
        "xl:px-42 " +
        "2xl:px-48 " +
        props.extraStyles
      }
    >
      <div className="flex flex-col mb-8">
        <div className="font-sofia font-bold text-5xl mb-1.5">
          {props.title1}
        </div>
        <div className="font-sofia font-bold text-5xl text-purple-700 mb-8">
          {props.title2}
        </div>
      </div>
      <div className={"flex flex-col w-full justify-between " + "lg:flex-row"}>
        <div className={"flex flex-col w-full pr-5 " + "lg:self-end w-5/12"}>
          <div className="font-rubik text-lg font-light mb-8">
            {props.paragraph}
          </div>
          <div className="flex flex-col bg-purple-100 p-4">
            <div className="font-sofia font-bold text-purple-700 mb-3 text-lg">
              Use cases
            </div>
            {props.highlights.map((data) => (
              <div className="flex flex-row font-medium mb-2">
                <CheckCircleIcon className="h-6 w-6 text-purple-600 mr-2 " />
                <div className="font-rubik text-gray-700">{data}</div>
              </div>
            ))}
          </div>
        </div>
        <img
          src={props.imgSrc}
          alt=""
          className={"rounded-sm object-cover w-full mt-4 " + "lg:w-6/12"}
        />
      </div>
    </div>
  );
}
