import scaleWithConfidence from "../../assets/scaleWithConfidence.svg";
import maximizeEfficiency from "../../assets/maximizeEfficiency.svg";
import accelerateTimeToMarket from "../../assets/accelerateToMarket.svg";

export default function FeatureSection() {
  return (
    <>
      <div
        className={
          "flex flex-col items-center justify-center mt-32 " +
          "xs:px-8 " +
          "sm:px-12 " +
          "md:px-16 " +
          "lg:px-32  " +
          "xl:px-42 " +
          "2xl:px-48 "
        }
      >
        <div className="text-4xl font-bold font-sofia text-center mb-4">
          Empower your enterprise with customizable automation
        </div>
        <div className="text-lg font-light text-center w-9/12">
          Boost your productivity, cut down costs, and streamline your interface
          with our no-code automation solution. Create high-quality applications
          in-house with ease and integrate them seamlessly with existing and new
          systems.
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-16 mb-32 2xl:px-48 xl:px-42 lg:px-32 md:px-24 sm:px-16 xs:px-10 bg-brand-0">
        <div
          className={
            "grid grid-cols-1 my-24 gap-4 " +
            "lg:grid-cols-3 " +
            "xl:grid-cols-3 " +
            "2xl:grid-cols3"
          }
        >
          <div className="flex flex-col  p-8 items-center">
            <img
              src={scaleWithConfidence}
              alt="feature Icon"
              className="w-24 h-24 mb-8"
            />
            <div className="h-fit text-brand-7 font-medium font-sofia text-xl text-center mb-4">
              Scale with confidence
            </div>
            <div className="h-fit text-center font-rubik">
              Achieve optimal scalability by digitalizing manual workflows and
              delivering a seamless user experience while maximizing
              customization options.
            </div>
          </div>
          <div className="flex flex-col  p-8 items-center">
            <img
              src={maximizeEfficiency}
              alt="feature Icon"
              className="w-24 h-24 mb-8"
            />
            <div className="h-fit text-brand-7 font-medium font-sofia text-xl text-center mb-4">
              Maximize efficiency
            </div>
            <div className="h-fit text-center font-rubik">
              Augment your business’s returns through more efficient and
              effective all in one platform.
            </div>
          </div>
          <div className="flex flex-col   p-8 items-center">
            <img
              src={accelerateTimeToMarket}
              alt="feature Icon"
              className="w-24 h-24 mb-8"
            />
            <div className="h-fit text-brand-7 font-medium font-sofia text-xl text-center mb-4">
              Shorten time to market
            </div>
            <div className="h-fit text-center font-rubik">
              Accelerate your digital initiatives with custom applications built
              processes, and shorten time to market with our out-of-the-box
              products.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
