import "./index.css";
import Solutions from "./pages/Solutions/Solutions";
import Integrations from "./pages/Integrations/Integrations";
import Home from "./pages/Home/Home";
import GetDemo from "./pages/GetDemo/GetDemo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./functions/scrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/get-demo" element={<GetDemo />} />
      </Routes>
    </Router>
  );
}

export default App;
