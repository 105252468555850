import ReadyToStartSection from "../../components/ReadyToStartSection";
import TopBar from "../../components/TopBar";
import MainSection from "./MainSection";

export default function Solutions() {
  return (
    <div>
      <TopBar />
      <div className="py-8" />
      <MainSection />
      <ReadyToStartSection background="bg-white" />
    </div>
  );
}
