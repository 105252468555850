import ReadyToStartSection from "../../components/ReadyToStartSection";
import TopBar from "../../components/TopBar";

import paypalLogo from "../../assets/integrations/paypal.svg";
import plaidLogo from "../../assets/integrations/plaid.svg";
import yodleeLogo from "../../assets/integrations/yodlee.svg";
import salesforceLogo from "../../assets/integrations/salesforce.svg";
import stripeLogo from "../../assets/integrations/stripe.svg";
import ebayLogo from "../../assets/integrations/ebay.svg";
import workdayLogo from "../../assets/integrations/workday.svg";
import sapLogo from "../../assets/integrations/sap.svg";

import dynamodbLogo from "../../assets/integrations/dynamodb.svg";
import mysqlLogo from "../../assets/integrations/mysql.svg";
import oracleLogo from "../../assets/integrations/oracle.svg";
import postgresqlLogo from "../../assets/integrations/postgresql.svg";
import mongodbLogo from "../../assets/integrations/mongodb.svg";
import auroradbLogo from "../../assets/integrations/auroradb.svg";
import mssql from "../../assets/integrations/mssql.svg";

import kafkaLogo from "../../assets/integrations/kafka.svg";
import sqsLogo from "../../assets/integrations/sqs.svg";
import jmsLogo from "../../assets/integrations/jms.svg";
import mskLogo from "../../assets/integrations/msk.png";

const integrations = {
  "API integrations": [
    {
      name: "Paypal",
      description: "PayPal is an online payment system ",
      logo: paypalLogo,
    },
    {
      name: "Plaid",
      description: "Plaid connects your financial data to a service",
      logo: plaidLogo,
    },
    {
      name: "Yodlee",
      description: "Yodlee offers account aggregation services. ",
      logo: yodleeLogo,
    },
    {
      name: "Salesforce",
      description: "Salesforce is cloud-based CRM software",
      logo: salesforceLogo,
    },
    {
      name: "SAP",
      description:
        "SAP is a well-known enterprise resource planning (ERP) software",
      logo: sapLogo,
    },
    {
      name: "Stripe",
      description: "Stripe's payment processing solution",
      logo: stripeLogo,
    },
    {
      name: "Ebay",
      description: "eBay is an online marketplace to buy and sell items.",
      logo: ebayLogo,
    },
    {
      name: "Workday",
      description: "Workday manages company's workforce",
      logo: workdayLogo,
    },
  ],
  "Database integrations": [
    {
      name: "DynamoDB",
      description: "DynamoDB is a fully managed NoSQL database service ",
      logo: dynamodbLogo,
    },
    {
      name: "MongoDB",
      description:
        "MongoDB is a document database with the scalability and flexibility",
      logo: mongodbLogo,
    },
    {
      name: "MYSQL",
      description:
        "MySQL is an open-source relational database management system",
      logo: mysqlLogo,
    },
    {
      name: "Oracle",
      description:
        "Oracle is a relational database management system (RDBMS). ",
      logo: oracleLogo,
    },
    {
      name: "PostgreSQL",
      description:
        "PostgreSQL is a open-source relational database management system",
      logo: postgresqlLogo,
    },
    {
      name: "MSSQL",
      description: "MSSQL is a relational database management system (RDBMS). ",
      logo: mssql,
    },
    {
      name: "AuroraDB",
      description: "Amazon Aurora is a fully managed relational database",
      logo: auroradbLogo,
    },
  ],
  "Queue integrations": [
    {
      name: "Kafka",
      description:
        "Kafka is a distributed event store and stream-processing platform",
      logo: kafkaLogo,
    },
    {
      name: "SQS",
      description: "SQS is a message management ",
      logo: sqsLogo,
    },
    {
      name: "JMS",
      description: "Java Messaging Service Queue",
      logo: jmsLogo,
    },
    {
      name: "MSK",
      description: "Amazon Managed Streaming for Apache Kafka",
      logo: mskLogo,
    },
  ],
};

const placeholderIntegrations = [
  salesforceLogo,
  paypalLogo,
  stripeLogo,
  plaidLogo,
  mongodbLogo,
];

export default function Integrations() {
  return (
    <div className="flex flex-col">
      <TopBar />
      <div className="py-8" />
      <div
        className={
          "py-32 bg-gray-800 px-4  flex flex-col " +
          "xs:px-8 " +
          "sm:px-12 " +
          "md:px-16 " +
          "lg:px-32 lg:flex-row " +
          "xl:px-42 xl:flex-row " +
          "2xl:px-48 2xl:flex-row "
        }
      >
        <div
          className={
            "flex flex-col w-full " +
            "xs:w-full " +
            "sm:w-full " +
            "md-w-full " +
            "lg:w-5/12 "
          }
        >
          <div className="font-rubik tracking-wider text-xs font-medium mb-4 text-brand-3">
            INTEGRATIONS
          </div>
          <div className="font-sofia text-5xl text-white font-bold mb-6">
            Seamless integrations with KonfigAI
          </div>
          <div className="font-rubik text-lg text-white font-light opacity-80">
            Seamlessly integrate with our no-code platform. Import Postman
            collections and connect to APIs and databases without coding.
            Built-in connectors and APIs enable integration with data sources
            and third-party services. Build custom applications and workflows
            with ease using our integration options.
          </div>
        </div>
        <div className="flex flex-grow justify-center">
          <div
            className={
              "grid grid-cols-3 gap-6 mt-6 grid-flow-row w-full" +
              "xs:mt-6 " +
              "sm:mt-6" +
              "md:mt-6" +
              "lg:ml-12 " +
              "xl:ml-14 " +
              "2xl:ml-16"
            }
          >
            {placeholderIntegrations.map((item) => {
              return (
                <img
                  className="w-32 h-32 bg-white rounded-sm transition hover:-translate-y-1 p-8"
                  src={item}
                  alt="integration placeholder"
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={
          "py-16 px-4  bg-gray-100 " +
          "xs:px-8 " +
          "sm:px-12 " +
          "md:px-16 " +
          "lg:px-32 lg:flex-row " +
          "xl:px-42 xl:flex-row " +
          "2xl:px-48 2xl:flex-row "
        }
      >
        <div className="font-sofia font-bold mb-4 text-lg ">
          Third party integrations
        </div>
        <div
          className={
            "grid grid-cols-2 gap-6 " +
            "xs:grid-cols- 2 " +
            "sm:grid-cols-2 " +
            "md:grid-cols-3 " +
            "lg:grid-cols-3 " +
            "xl:grid-cols-4 " +
            "2xl:grid-cols-4 "
          }
        >
          {integrations["API integrations"].map((data) => (
            <button className="bg-white hover:shadow-[0_20px_30px_rgba(0,0,0,0.035)] p-8 rounded-sm flex flex-col  gap-4 font-medium justify-center items-center hover:-translate-y-2 transition">
              <div className="rounded-full text-center p-3 bg-gray-100">
                <img src={data.logo} className="w-10 h-10" alt="" />
              </div>
              <div className="font-sofia font-bold text-lg text-center">
                {data.name}
              </div>
              <div className="font-rubik font-light text-md text-center">
                {data.description}
              </div>
            </button>
          ))}
        </div>

        <div className="font-sofia font-bold mt-16 mb-4 text-lg ">
          Database Integrations
        </div>
        <div
          className={
            "grid grid-cols-2 gap-6 " +
            "xs:grid-cols- 2 " +
            "sm:grid-cols-2 " +
            "md:grid-cols-3 " +
            "lg:grid-cols-3 " +
            "xl:grid-cols-4 " +
            "2xl:grid-cols-4 "
          }
        >
          {integrations["Database integrations"].map((data) => (
            <button className="hover:shadow-[0_20px_30px_rgba(0,0,0,0.035)] bg-white  p-8 rounded-sm flex flex-col  gap-4 font-medium justify-center items-center hover:-translate-y-2 transition">
              <div className="rounded-full text-center p-3 bg-gray-100">
                <img src={data.logo} className="w-10 h-10" alt="" />
              </div>
              <div className="font-sofia font-bold text-lg text-center">
                {data.name}
              </div>
              <div className="font-rubik font-light text-md text-center">
                {data.description}
              </div>
            </button>
          ))}
        </div>
        <div className="font-sofia font-bold mt-16 mb-4 text-lg ">
          Kafka Integrations
        </div>
        <div
          className={
            "grid grid-cols-2 gap-6 " +
            "xs:grid-cols- 2 " +
            "sm:grid-cols-2 " +
            "md:grid-cols-3 " +
            "lg:grid-cols-3 " +
            "xl:grid-cols-4 " +
            "2xl:grid-cols-4 "
          }
        >
          {integrations["Queue integrations"].map((data) => (
            <button className="hover:shadow-[0_20px_30px_rgba(0,0,0,0.035)] bg-white  p-8 rounded-sm flex flex-col  gap-4 font-medium justify-center items-center hover:-translate-y-2 transition">
              <div className="rounded-full text-center p-3 bg-gray-100">
                <img src={data.logo} className="w-10 h-10" alt="" />
              </div>
              <div className="font-sofia font-bold text-lg text-center">
                {data.name}
              </div>
              <div className="font-rubik font-light text-md text-center">
                {data.description}
              </div>
            </button>
          ))}
        </div>
      </div>

      <ReadyToStartSection background="bg-gray-100" />
    </div>
  );
}
